import request from './request'

export const consolesDetail = () => {
  return request({
    url: 'consoles/detail'
  })
}
export const investmentRecords = () => {
  return request({
    url: 'consoles/investmentRecords'
  })
}
export const dailyActive = () => {
  return request({
    url: 'consoles/dailyActive'
  })
}
